(function () {
  'use strict';

  angular
    .module('people')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('people', {
        abstract: true,
        url: '/people',
        templateUrl: 'people/people.tpl.html'
      })
      .state('people.export', {
        url: '/export',
        templateUrl: 'people/export.tpl.html',
        controller: 'ExportCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
